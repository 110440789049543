import React from 'react';
import NewWindowLink from '../Link/index';

const Help = () => {    
  const urlBase = process.env.REACT_APP_ENV === "U" ? `https://orgcharttest.fdot.gov` : 'https://orgchart.fdot.gov';
  return (
    <React.Fragment>        
         <strong>
                About this site
            </strong>
            <p>
                This site provides an interactive view of the Florida Department of Transportation’s organizational structure.
            </p>


            <strong>Disclaimer</strong>

            <p>
                Information that is submitted to the Florida Department of Transportation is
                open for personal inspection and copying by any person in accordance with Chapter 119, Florida Statutes (F.S.).
            </p>

            <strong>Organizational charts Helpful Links:</strong>
            <ul>
            <li>
                    <NewWindowLink
                        href={`${urlBase}/99`}                        
                        linkText="Central Office">
                    </NewWindowLink>
                </li>
                <li>
                    <NewWindowLink
                        href={`${urlBase}/01`}                        
                        linkText="District 01">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/02`}                        
                        linkText="District 02">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/03`}                        
                        linkText="District 03">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/04`}                        
                        linkText="District 04">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/05`}                        
                        linkText="District 05">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/06`}
                        linkText="District 06">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/07`}  
                        linkText="District 07">
                    </NewWindowLink>
                </li>
                <li>
                <NewWindowLink
                        href={`${urlBase}/08`}
                        linkText="Turnpike">
                    </NewWindowLink>
                </li>                
            </ul>
    </React.Fragment>
  )
};

export default Help;