import React from 'react';

const HelpDesk = () => {
    return (
        <div className="text-center" style={{position:"absolute",bottom:0,width:"100%",backgroundColor:"#33557C",color:"white"}}>
            <strong> For Technical Issues Contact Help: <a href='mailto:FDOT.ServiceDesk@dot.state.fl.us?subject=FDOT%20Application%20Access%20Inquiry&body=Application%20Name%3A%0D%0AApplication%20URL%3A%0D%0AQuestion%3A' title="Email Service Desk">Email Service Desk</a> &nbsp;or call 1-866-955-4357(HELP)<br /></strong>
            <br />
        </div>
    )
}

export default HelpDesk;