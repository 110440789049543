import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
// import { CssBaseline } from '@material-ui/core'; 
import AppBarMenu from '../../components/AppBar/index';
// import Footer from '../../components/Footer/index';
import HelpDesk from '../../components/Footer/helpdesk';
import Routing from '../../components/Routing/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dispatch } from '../../configuration/store';

const useStyles = makeStyles(theme => ({
    root: { display: 'flex' },
    content: {
        position: "absolute",
        flex: 1,
        height: '95%',
        width: "100%",
        overflow: 'auto',
    },
    container: {
        marginTop: theme.spacing(22) // TODO: Adjust based on final result
    },
    hiddenScreenReaderAccessible: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden'
    }
}));

const Home = () => {
    const classes = useStyles();
    const setContentFocus = () => {
        console.log('set main content focus');
        dispatch.org.setExpandedDescendants(true);
    }
    return (
        <div>
                {/* <CssBaseline/> */}
                <a href="#maincontent" className={classes.hiddenScreenReaderAccessible} onClick={setContentFocus}>
                    Skip to main content
                </a>
                <AppBarMenu />
                <div className={classes.content}>
                    <Container maxWidth="xl" className={classes.container}>
                        <ToastContainer position="top-right" autoClose={5000} pauseOnHover={true} pauseOnFocusLoss={true}/>
                        <Routing/>
                    </Container>                    
                </div>
                {/* <Footer/>        */}
                <div className="container-fluid">
                    <HelpDesk/>
                </div>
        </div>
    )
}

export default Home;