import React from 'react';
import Node from './card';
import {OrgChartProps} from '../../classes/interfaces';
import { makeStyles } from '@material-ui/core';

  const useStyles = makeStyles({
    nodeDiv:{
        display: "inline-block",        
        padding: 2, 
        '&:hover': {            
            'z-index': 10,
            transform: 'scale(1.1)'
         }
    }
});
  
export const OrgChartNodeComponent = (props: OrgChartProps) => {
    const { node, selectedNode, onSelectedNode } = props;
    const isSelectedNode = node.staffMember.id === selectedNode.staffMember.id;    
    const classes = useStyles();
    return (
        <div className={classes.nodeDiv} style={{ height: isSelectedNode ? 135 : 130, width: isSelectedNode ? "250px" : "200px"}}>
            <Node node={node} isSelectedNode={isSelectedNode} onSelectedNode={onSelectedNode} />
        </div>
    );
};

export default OrgChartNodeComponent;