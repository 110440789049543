import React from 'react';
import './fdot.css';
import Menu from './menu';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Header = () => {    
    const matches = useMediaQuery('(min-width:991px)');    
    return (
        <div className="fdot-header-container clearfix">
            <div id="fdot-header" className="clearfix">                
                <div id="fdot-header-logo" className="pull-left">
                    <img src="https://webincludes.dot.state.fl.us/images/FDOTLogo.png" alt="State of Florida Department of Transportation logo" title="State of Florida Department of Transportation logo" />
                </div>
                <div id="fdot-header-top">
                    <div id="fdot-header-tagline">
                        <div id="fdot-header-tagline-line-1"><a href="http://www.fdot.gov">Florida Department of</a></div>
                        <div id="fdot-header-tagline-line-2"><a href="http://www.fdot.gov">TRANSPORTATION</a></div>
                    </div>
                    {matches && <div style={{position:"absolute", top:0,right:45,flex:1,textAlign:"end"}}>
                        <div><em style={{fontSize: '14px', color: '#1f4182', fontWeight: "bold"}}>Organizational Chart</em></div>
                        <div><em style={{fontSize: '14px', color: '#1f4182', fontWeight: "bold"}}>Improve Safety, Enhance Mobility, Inspire Innovation</em></div>                        
                    </div>}                    
                </div>
                <Menu/>
            </div>
        </div>
    )
}

Header.propTypes = {
    SiteCatalogSearch: PropTypes.any
}
export default Header;