import React from 'react';
import {OrgChartProps} from '../../classes/interfaces';
import RenderNode from './node';
// import {IconButton,HelpIcon} from '@material-ui/core';
import HelpButton from '../../components/help/helpbutton';

const OrganizationOrgChartComponent = (props:OrgChartProps) => {    
    const {node, path, selectedNode, onSelectedNode} = props;
    return (
          <div>                
                <RenderNode node={node}                     
                            path={path} 
                            selectedNode={selectedNode} 
                            onSelectedNode={onSelectedNode} key={node.staffMember.id}/>
                            <div>
                            {/* <Button
                                    variant="contained"                                    
                                    size="small"
                                    style={{position:"absolute",top:"160px",right:2,backgroundColor:"#33557C"}}
                                    startIcon={<HelpIcon style={{fontSize:"30px",color:"white"}}/>}
                                    >
                                    <span style={{color:"white"}}>Help</span>
                            </Button> */}
                      <HelpButton/>
                </div>
          </div>
    )
};

export default OrganizationOrgChartComponent;