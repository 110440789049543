import React from 'react';

interface IProps {
    href: string;    
    linkText: string
}
const NewWindowLink: React.FC<IProps> = (props: IProps) => {    
    return (<a
        target="_blank"
        rel="noopener noreferrer"
        href={props.href}
        title={props.linkText}>
        {props.linkText}
    </a>)
};

export default NewWindowLink;