import React from 'react';
import { useState } from 'react';
const Menu = () => {
    const [collapsed,setCollapse] = useState(false);
    const toggleNavbar = () => {setCollapse(!collapsed)}
    return(
        <div className="navbar navbar-inverse" id="fdot-header-nav">
            <div className="navbar-header" id="fdot-header-navbar-brand">
                <button type="button" onClick={toggleNavbar} className={collapsed ? 'navbar-toggle' : 'navbar-toggle collapsed'} data-toggle="collapse" data-target="#fdot-header-nav-bar">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
            </div>
            <div className={collapsed ? 'navbar-collapse in' : 'navbar-collapse collapse'} id="fdot-header-nav-bar">
                <ul className="nav navbar-nav navbar-right">
                    <li><a href="http://www.fdot.gov/default.shtm" title="Home">Home</a></li>                        
                    <li><a href="http://www.fdot.gov/agencyresources/aboutFDOT.shtm" title="About FDOT">About FDOT</a></li>
                    <li><a href="http://www.fdot.gov/agencyresources/contactus.shtm" title="Contact Us">Contact Us</a></li>
                    <li><a href="http://www.fdot.gov/agencyresources/mapsanddata.shtm" title="Maps & Data">Maps & Data</a></li>
                    <li><a href="http://www.fdot.gov/agencyresources/offices.shtm" title="Offices">Offices</a></li>
                    <li><a href="http://www.fdot.gov/agencyresources/performance.shtm" title="Performance">Performance</a></li>
                    <li><a href="https://fdotewp1.dot.state.fl.us/stateconstructionoffice/" title="Projects">Projects</a></li>
                </ul>
            </div>
            <div className="left-triangle pull-right">
            </div>
        </div>
    )
}

export default Menu;