import React, { useEffect } from 'react';
import { Route, Switch} from 'react-router-dom';
import Search from '../../containers/District/index';
import Index from '../../containers/OrgChart/index';
import Help from '../../components/help/index';

import {loadAppInsights} from '../../configuration/appInsights';

const Routing = () => {
    useEffect(() => {loadAppInsights()},[]);
    return (        
        <Switch>
            <Route exact path='/' component={Index} />
            <Route exact path='/helper' component={Help}/>   
            <Route path='/:districtCode' component={Search} />            
        </Switch>        
    )
}

export default Routing;