import React from "react";
// import Header from "../Header/v2/index";
import Header from "../Header/index";
import { AppBar } from "@material-ui/core";

const AppBarMenu = () => {    
    return (
    <AppBar position="absolute" style={{ backgroundColor: "white" }}>
        <Header />        
    </AppBar>)
}

export default AppBarMenu;