import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Home from './containers/Home/index';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './configuration/store';
import { Provider } from "react-redux";

function Application() {  
  const theme = createMuiTheme();
  return (
    <ThemeProvider theme={theme}>
      <Router>
          <Home/>
      </Router>        
    </ThemeProvider>      
  );
}

const App: React.FC = () => {
  return (
    <Provider store={store}>      
      <Application />
    </Provider>
  );
}


export default App;
