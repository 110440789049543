import React, { useEffect } from 'react';
import Loading from '../../components/Loading/index';

interface IndexProps { history: any }
const Index = ({history}: IndexProps) => {
    useEffect(() => { history.push(`/${process.env.REACT_APP_CENTRAL_OFFICE_DISTRICT_CODE}`) }, [history]);
    return (
        <Loading />
    )
};

export default Index;