import { createModel } from '@rematch/core';
import {ErrorMessage} from '../classes/interfaces';

export const error = createModel({
	state: { message: '' },
	reducers: {		
		setErrorMessage: (state: any, error: ErrorMessage): string => { return { ...state.message, message: error.message,type: error.type, model  : error.model  } },		
	},
	effects: {
		// TODO: Optional args breaks TypeScript autocomplete (e.g. payload: number = 1)		
	},
})
