import axios from 'axios';
import { ArculusOrgChartApiResponse } from '../interfaces';
import OrgChartNode from '../OrgChartNode';

export const selectDistrictStaffGraphNode = async (district: string): Promise<OrgChartNode> =>
{
    if (process.env.REACT_APP_ARC_ORG_CHART_API_URL === undefined) throw new Error("Org chart back end service api url is missing");
    if (process.env.REACT_APP_ENV === undefined) throw new Error("REACT_APP_ENV: Environment undefined");
    const apiUrl: string = `${process.env.REACT_APP_ARC_ORG_CHART_API_URL}/api/v1/staff/hierarchy`;
    let node: ArculusOrgChartApiResponse<OrgChartNode>;
    node = await axios.get(apiUrl, { params: { district: district } });
    return node.data;
}

export const selectSecretaryPhotoUrl = async (): Promise<string> =>
{
    const apiUrl: string = `${process.env.REACT_APP_ARC_ORG_CHART_API_URL}/api/v1/profile/root/photo`;
    const response: ArculusOrgChartApiResponse<string> = await axios.get(apiUrl, { params: { base64: true } });
    return response?.data;
}