import React from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import NodeComponent from './component';
import { OrgChartProps } from '../../classes/interfaces';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(0.3),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        divider: {
            color: "blue",
            backgroundColor: "blue"
        },
        node: {
            textAlign: "center", alignItems: "center", justifyContent: "center", width: "100%"
        }
    }),
);

const RenderNode = (props: OrgChartProps) => {
    const classes = useStyles();
    const { node, path, selectedNode, onSelectedNode } = props;
    const filteredDirectReports = node.directReports ? node.directReports.filter(c => path.some(p => p === c.staffMember.id)) : [];
    const hasDirectReports = node.directReports && node.directReports.length > 0;    
    return (
        <div className={classes.root}>
            <Grid className={classes.node}>
                <Grid item xs={"auto"}>
                    <NodeComponent node={node} selectedNode={selectedNode} onSelectedNode={onSelectedNode} path={path}/>
                </Grid>
                {node.staffMember.id === selectedNode.staffMember.id && hasDirectReports && <Box display="flex" justifyContent="center" style={{ width: "100%" }}><Divider orientation="vertical" style={{ height: '20px', backgroundColor: "rgb(45, 100, 173)" }} /></Box>}
                {node.staffMember.id === selectedNode.staffMember.id && hasDirectReports && <Divider light style={{ width: '100%', height: '1px', backgroundColor: "rgb(45, 100, 173)" }} />}
                {node.staffMember.id !== selectedNode.staffMember.id && filteredDirectReports.map((c, index) => ((
                    <RenderNode key={c.staffMember.id} onSelectedNode={onSelectedNode}
                        node={c} path={path} selectedNode={selectedNode} />)))}
                <Grid container item xs={"auto"}>
                    {node.staffMember.id === selectedNode.staffMember.id && node.directReports.map((c, index) => (
                        <RenderNode key={c.staffMember.id}
                            node={c} path={path} selectedNode={selectedNode}
                            onSelectedNode={onSelectedNode} />))}
                </Grid>
            </Grid>
        </div>
    )
};

export default RenderNode;