import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/index';
import { toast } from 'react-toastify';
import { dispatch } from '../../configuration/store';
import { getOrgCode } from '../../classes/services/orgCodeService';
import OrgChart from '../OrgChart/root';
import {appInsights} from '../../configuration/appInsights';

export interface DistrictProps { match?: any }

const District = ({ match }: DistrictProps) => {
    const districtCode = match.params && match.params.districtCode;
    const [loading, setLoading] = useState(true);
    const [showOrgChart,setShowOrgChart] = useState(false);
    const setError = (error: string) => { setLoading(false); toast.error(error); }
    const searches = async () => {
        if (!districtCode) setError("District is a required parameter in the URL");
        const orgCode = await getOrgCode(districtCode);
        if (!orgCode) { setError("Invalid District passed...") }
        else 
        {                  
            await dispatch.org.LoadChart(districtCode)
            .then(r => {
                setShowOrgChart(true)
            })
            .catch(e => {
                console.log(e);                
                toast.error(`an error occured while building the chart ${e}`);
                setShowOrgChart(false);
                appInsights.trackException(e)})
            .finally(() => {setLoading(false);});
        }        
    }
    useEffect(() => { 
        const runSearch = async() => { await searches(); } 
        runSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {loading && <Loading />}
            {showOrgChart && <OrgChart/>}
        </React.Fragment>
    )
};

export default District;