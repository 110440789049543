import React from 'react';
import './loader.css'
const Loading = () => {

    return (
        <div className="loader">
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
        </div>
    )
};

export default Loading;