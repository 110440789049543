import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {IconButton} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const Help = (props:any) => {
    const history = props.history;
    const toHelp = () => {history.push("/helper");}
    return (
        <IconButton style={{position:"absolute",top:"150px",right:0,color:"#33557C",marginBottom:15}}
                color="primary" aria-label="help page" component="span"
                onClick={toHelp}>
            <HelpIcon style={{fontSize:"25px"}}/> Help
        </IconButton>
    )
};

Help.propTypes = {
      history: PropTypes.any
}
export default withRouter(Help);