import React from 'react';
import {Avatar} from '@material-ui/core';
interface CustomAvatarProps {alt:string, src: string | undefined,isProtected:boolean}
const CustomAvatar = ({alt,src,isProtected}: CustomAvatarProps) =>{      
    const names = alt.split(' ');
    const initials = isProtected ? "" : `${names[0] ? names[0][0] : ""}${names[1] ? names[1][0] : ""}`;
    return(
        <React.Fragment>
            {src && <Avatar alt={alt} src={src}/>}
            {!src && <Avatar alt={alt} style={{backgroundColor:"#D2D7E1",color:"#1A2C3F",fontSize:'20px'}}>{initials}</Avatar>}
        </React.Fragment>
        
    )
}

export default CustomAvatar;