import { init,RematchRootState  } from '@rematch/core';
import selectPlugin from '@rematch/select';
import * as models from './models';

// const options = {}
// const loading = createLoadingPlugin(options);
const reduxMiddleware : any[]  = []

if (process.env.NODE_ENV !== 'production') {
    const freeze = require('redux-freeze')
    reduxMiddleware.push(freeze)
}

const store = init({
    plugins: [selectPlugin()], // ,loading
    models,    
    redux:{middlewares:reduxMiddleware}
});

export const { select , dispatch,getState } = store

export default store;

export type Store = typeof store
export type Dispatch = typeof store.dispatch
export type iRootState = RematchRootState<typeof models>