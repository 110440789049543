import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export var appInsights: ApplicationInsights;

export const loadAppInsights = () => {
    try {
        if (appInsights) return;    
        if (process.env.REACT_APP_ENV !== 'U') {
            appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
                    autoTrackPageVisitTime: true,
                    enableAutoRouteTracking: true
                }
            });
            appInsights.loadAppInsights();
        }
        else {
            appInsights = {
                trackEvent: () => { },
                trackException: () => { }            
            } as any as ApplicationInsights;
        }   
    } catch (error) {
        console.log('cannot load App Insights', error);
    }    
}