import { createModel } from '@rematch/core';
import OrgChartNode from '../classes/OrgChartNode';
import { selectDistrictStaffGraphNode, selectSecretaryPhotoUrl } from '../classes/services/staffService';

export const org = createModel({
        state: { node: null, expandedDescendants: false, secretaryPhotoUrl: '', districtCode:''},
        reducers: {
                setDistrictCode: (state: any, district: string): OrgChartNode => { return { ...state, districtCode: district } },
                setPhotoUrl: (state: any, url: string): OrgChartNode => { return { ...state, secretaryPhotoUrl: url } },
                setNode: (state: any, node: OrgChartNode[]): OrgChartNode => { return { ...state, node: node } },
                setExpandedDescendants: (state: any, expandedDescendants: boolean): OrgChartNode => { return { ...state, expandedDescendants: expandedDescendants } }
        },
        effects: {
                async LoadChart(district: string)
                {
                        let tree: OrgChartNode = await selectDistrictStaffGraphNode(district);
                        this.setDistrictCode(district);
                        if (process.env.REACT_APP_CENTRAL_OFFICE_DISTRICT_CODE === district)
                        {
                                const photo = await selectSecretaryPhotoUrl();
                                if(photo) this.setPhotoUrl(photo);
                        }
                        this.setNode(tree);
                }
        },
})