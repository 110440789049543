import axios from 'axios';
import { OrgCode } from '../../classes/interfaces';

export const selectOrgCodes = async () : Promise<OrgCode[]> => {
    const url = `${process.env.REACT_APP_SERVICE_ORG_CODE_API_URL}`;    
    const orgCodes: OrgCode[] = await axios.get(`${url}`);   
    return orgCodes; 
}

export const getOrgCode = async(districtOrgCode: string): Promise<OrgCode | undefined> => {    
    const response : any = await selectOrgCodes();
    const orgCodes : OrgCode[] = response.data; 
    return orgCodes.filter(o => o.codeValue === districtOrgCode).shift();
}