import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iRootState } from '../../configuration/store';
import OrgChartNode from '../../classes/OrgChartNode';
import OrgChart from './orgchart';
import { toast } from 'react-toastify';

const OrganizationOrgChart = () => {    
    const org = useSelector<iRootState, any>(state => state.org);
    const [orgchart, setOrgChart] = useState<OrgChartNode | null>(null);
    const [path, setPath] = useState<number[]>([]);
    const [selectedNode,setSelectedNode] = useState<OrgChartNode>();
    useEffect(() => {
      if(!org.node) toast.warn("No member found in the requested district");
      org.node && org.node.staffMember && setPath([org.node.staffMember.id]);
      setOrgChart(org.node);
      setSelectedNode(org.node);}, [org.node]);    
    const onSelectedNode = async (node: OrgChartNode) => {        
        const index = path.indexOf(node.staffMember.id);
        if(index !== -1)
        {                        
            let refreshed = [...path.slice(0,index),node.staffMember.id];
            setPath(refreshed);
        }
        else setPath((p) => [...p,node.staffMember.id]);
        setSelectedNode(node);
      }
  
  return (
    <React.Fragment>        
        {selectedNode && orgchart && <OrgChart node={orgchart} path={path} selectedNode={selectedNode} onSelectedNode={onSelectedNode}/>}
    </React.Fragment>
  )
};

export default OrganizationOrgChart;