import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardHeader, CardContent, Divider, Tooltip, Chip, Badge } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import CustomAvatar from '../../components/Avatar/index';
import OrgChartNode from '../../classes/OrgChartNode';
import { dispatch, iRootState } from '../../configuration/store';
import { useSelector } from 'react-redux';
import { DISTRICT_SECRETARY_TITLE } from '../../constants/StaffConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        Width: 200,
        height: 130
    },
    selectedRoot: {
        Width: 200,
        height: 135,
        border: '3px solid #32557c'
    },
    badge: {
        backgroundColor: "#33557C",
        color: "white",
      }
}));

export const TextOnlyTooltip = withStyles({
    tooltip: {
        fontSize: "12px"
    }
})(Tooltip);

interface NodeCardItemProps { node: OrgChartNode, isSelectedNode: boolean, onSelectedNode: (node: OrgChartNode) => void }
export default function NodeCardItem(props: NodeCardItemProps)
{
    let button: any = useRef(null);
    const expandedDescendants = useSelector<iRootState, boolean>(state => state.org.expandedDescendants);
    const rootDistrictCode = useSelector<iRootState, any>(state => state.org.districtCode);
    const rootPhotoUrl = useSelector<iRootState, string>(state => state.org.secretaryPhotoUrl);
    const { node, isSelectedNode, onSelectedNode } = props;
    const isVacant = node.staffMember.isVacant;
    const isInterim = node.staffMember.isInterim;
    const name = !isVacant ? `${node.staffMember.firstName} ${node.staffMember.lastName}` : "VACANT";
    const districtCode: number = parseInt(node.staffMember.district);
    // let isProtected = false;
    // if(process.env.PROTECTED_EMPLOYEE_NAME)  isProtected = name === process.env.PROTECTED_EMPLOYEE_NAME;
    const positionTitle = node.staffMember.positionWorkTitle;
    const interimPositionTitle = node.staffMember.interimPositionTitle;
    const showInterimPositionTitle = isVacant || isInterim;
    const isDistrictSecretary = (rootDistrictCode === process.env.REACT_APP_CENTRAL_OFFICE_DISTRICT_CODE && (positionTitle.includes(DISTRICT_SECRETARY_TITLE) || interimPositionTitle.includes(DISTRICT_SECRETARY_TITLE)));
    const classes = useStyles();
    useEffect(() => { if (isSelectedNode && expandedDescendants) { button.current.focus(); } }, [isSelectedNode, expandedDescendants]);
    const url = positionTitle === process.env.REACT_APP_SECRETARY_POS_TITLE ? rootPhotoUrl : '';
    const onNodeSelected = () =>
    {
        onSelectedNode(node);
        dispatch.org.setExpandedDescendants(true);
    }
    return (
        <Card className={clsx(classes.root, isSelectedNode && classes.selectedRoot)}>
            <TextOnlyTooltip title="Click to expand organizational chart" placement="top" arrow>
                <CardActionArea style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}
                    onClick={() => { onNodeSelected() }}
                    ref={button}
                >
                    <CardHeader
                        avatar={<CustomAvatar alt={name} src={url} isProtected={false} />}
                        title={name}
                        titleTypographyProps={{ variant: 'inherit' }}
                        style={{ backgroundColor: "#33557C", color: "white" }}
                    />
                    <CardContent>
                        <Divider style={{ height: '1px', backgroundColor: "#8691ca" }} />
                        {isDistrictSecretary && (<Badge overlap="circle" badgeContent={`D${districtCode}`} classes={{badge: classes.badge }}>
                            </Badge>)}
                        <Typography gutterBottom variant="body2" style={{ fontSize: "10px", fontWeight: "bold", paddingTop: 2 }}>
                            {showInterimPositionTitle && `${interimPositionTitle}`}
                            {!showInterimPositionTitle && positionTitle}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </TextOnlyTooltip>
        </Card>
    );
}
